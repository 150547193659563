import {
  callicon5,
  emailcion2,
  gifticon1,
  homeicon23,
  imagekpo,
  imagekpo2,
  Outboundicon,
  plushsiconimg,
  vanicon2,
} from "src/assets";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>
          KPO/BPO | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">KPO/BPO</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text pb-3">
                      Unlock A World of Expertise: Tailored KPO/BPO Services to
                      Elevate Your Business Productivity and Drive Growth that
                      Stays!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#contact-form"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="justify-content-between" data-aos="fade-up">
              <div className="img-shadow4">
                <img loading="lazy" src={imagekpo2} className="w-100" alt="line" />
              </div>
            </div>
            <div
              className="bg-color-box"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="row align-items-center revers-col g-3">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h2>
                    An Introduction to Our Robust KPO and BPO Services&nbsp;
                  </h2>
                  <p>
                    Transform your business productivity with our effective
                    KPO/BPO services. Our services cover everything ranging from
                    contact center solutions, freight brokerage, dispatch
                    services, chat/email solutions, inbound services, and
                    outbound calling services. With a track record of providing
                    effective solutions for years, we ensure boosting the
                    productivity of your business and scale operations
                    accordingly in the long run.
                  </p>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 text-center">
                  <img loading="lazy" className="w-100" src={imagekpo} alt="line" />
                </div>
              </div>
            </div>
            <div className="row iconCardBox-container grapich-box56 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-4">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="img-icon aos-init">
                    <img loading="lazy" src={callicon5} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Call Centre</h5>
                  <p className="text-gray text-center">
                    Turn business hurdles into winning opportunities with our
                    effective contact center solutions. Ensure seamless
                    communications with our effective services spanning
                    technical assistance, customer support.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-4">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="img-icon aos-init">
                    <img loading="lazy" src={vanicon2} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Freight Brokerage</h5>
                  <p className="text-gray text-center">
                    Our freight brokerage services are the answer to all of your
                    shipment dilemmas!&nbsp;&nbsp;manage cargo deliveries and
                    pick up like a pro, provide efficient and real-time
                    tracking.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-4">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="img-icon aos-init">
                    <img loading="lazy" src={gifticon1} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Dispatch Services</h5>
                  <p className="text-gray text-center">
                    Rev up your logistics with our truck dispatch services! We
                    take care of your navigating routes, vehicle assignments,
                    paperwork, and scheduling
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-4">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="img-icon aos-init">
                    <img loading="lazy" src={emailcion2} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Chat/ Email Services</h5>
                  <p className="text-gray text-center">
                    Our chat/email services are all that you need to make a
                    lasting impression on your present and potential
                    customers.&nbsp;
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-4">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="img-icon aos-init">
                    <img loading="lazy" src={homeicon23} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Inbound Services</h5>
                  <p className="text-gray text-center">
                    Our dedicated team provides comprehensive customer support,
                    addressing queries, processing orders, and resolving issues
                    across multiple channels
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-4">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="img-icon aos-init">
                    <img loading="lazy" src={Outboundicon} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">
                    Outbound Calling Services{" "}
                  </h5>
                  <p className="text-gray text-center">
                    Ditinus Technology initiates outbound calls for generating
                    leads, sales, customer follow-ups, and scheduling
                    appointments, helping your business expand its reach and
                    boost revenue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 text-center d-flex justify-content-center my-4">
              <a
                href="#contact-form"
                className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Start Your Project{" "}
                <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
              </a>
            </div>
          </div>
        </section>
        <section className="out-source">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11 col-md-12 col-12 text-center ">
                <h2 className="heading-02">
                  Why Outsource Your Business Needs To Us?
                </h2>
                <p
                  className="text-WH fw-lighter "
                  style={{ marginBottom: "145px" }}
                >
                  Explore the benefits of outsourcing your business needs to the
                  best business outsourcing agency, where you get a powerful{" "}
                  <br />
                  combination of innovation, technology, and cost-effectiveness
                  all in one place.
                </p>
              </div>
            </div>
            <div className="out-source-box">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="item-source" data-aos="fade-left">
                    <h2>1</h2>
                    <div className="text-item1">
                      <h4>Expertise and Skill</h4>
                      <p>
                        Take advantage of our specialized skills and knowledge
                        in handling various business operations with our KPO/BPO
                        services, ensuring your objectives are met efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="item-source" data-aos="fade-right">
                    <h2>2</h2>
                    <div className="text-item1">
                      <h4>Cost-Effective Solutions</h4>
                      <p>
                        Save big on business operations by outsourcing tasks to
                        us, eliminating the need for expensive in-house
                        resources, infrastructure, and overhead expenses.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="item-source" data-aos="fade-left">
                    <h2>3</h2>
                    <div className="text-item1">
                      <h4>Focus on Main Business Operations</h4>
                      <p>
                        Forget about handling every task yourself! Our KPO and
                        BPO services help you focus on your core business
                        operations while we manage non-core functions
                        efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="item-source" data-aos="fade-right">
                    <h2>4</h2>
                    <div className="text-item1">
                      <h4>Scalability</h4>
                      <p>
                        Easily scale your business operations with our
                        outsourcing services based on fluctuating demands,
                        without the hassle of hiring, training, or managing
                        additional staff.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="plush-img">
                <img loading="lazy" src={plushsiconimg} alt="icon" className="w-100" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
