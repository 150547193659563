import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  adobe_xd_icon,
  Bootstrap_logo,
  bottomline,
  crm,
  Deployment456,
  Design45,
  dotnet,
  ecommerc5,
  htmllogo,
  htmllogo2,
  Implementation34,
  javascriptlogo2,
  Laravellogo,
  lms,
  Maintenance4545,
  mobiaap,
  Nodejs_logo,
  phplogo,
  reactlogo2,
  requirement1,
  softwareimg12,
  Testing45,
  webdevelop,
  wordPress,
  wordpresslogo2,
} from "src/assets";
import { Helmet } from "react-helmet";

const Index = () => {
  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>
          Software Development | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-software-development">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">Development</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text">
                      HIGH-END DEVELOPMENT&nbsp;to upscale your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3">
                <a
                  href="#contact-form"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="image-text-box">
              <div
                className="row g-3 matop revers-col"
                data-aos="fade-up"
                data-aos-duration={500}
              >
                <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                  <h2 className="heading-02 lh-sm">
                    <span
                      className="position-relative d-inline-block"
                      data-aos="fade-left"
                    >
                      Why Choose Us
                      <img
                        src={bottomline}
                        alt="line"
                        className="position-absolute start-0 top-100 text-bottom-line w-100"
                      />
                    </span>
                  </h2>
                  <p className="matop">
                    Our software development process focuses on creating
                    high-quality, user-centric applications that address
                    real-world challenges. We emphasize usability,
                    functionality, and performance to deliver solutions that
                    meet our users' needs effectively.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 pading-img text-center">
                  <img loading="lazy" className="img-fluid" src={softwareimg12} />
                </div>
              </div>
            </div>
            <div
              className="row g-3 matop text-center"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <h2 className="heading-02 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                >
                  Web Development Services
                  <div className="middle-img">
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute top-100"
                    />
                  </div>
                </span>
              </h2>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={wordPress} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">WordPress</h5>
                  <p className="text-gray text-center">
                    Create highly functional, high-performing websites that put
                    your brand at the forefront of digital excellence. With our
                    WordPress solutions, we ensure your websites deliver
                    top-notch performance while being fully optimized, secure,
                    and future ready.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={crm} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">CRM</h5>
                  <p className="text-gray text-center">
                    Manage your customer interactions efficiently with our CRM
                    development services. From managing interactions and
                    contacts to tracking leads our robust platform is all you
                    need to succeed in your business aspirations.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={lms} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">LMS</h5>
                  <p className="text-gray text-center">
                    Harness the power of powerful learning platforms with our
                    LMS services where we build robust platforms designed to
                    streamline and enhance the learning experience for your
                    organization.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={webdevelop} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Web Development</h5>
                  <p className="text-gray text-center">
                    We tailor our services to meet your unique business needs with our comprehensive website development services. Our team crafts responsive and aesthetically appealing websites that deliver exceptional results and drive user engagement.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={mobiaap} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Web-Apps</h5>
                  <p className="text-gray text-center">
                    Our web app development services are designed to offer high-performance solutions tailored to your business needs. Whether you need a custom web application or a dynamic platform, our team utilizes the latest technologies to create user-friendly, secure, and responsive apps.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox text-center"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ecommerc5} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">E-commerce</h5>
                  <p className="text-gray text-center">
                    We specialize in designing and developing high-performance
                    custom eCommerce applications that not only elevate your
                    brand but also drive sales and foster growth. We use the
                    latest technologies and industry best practices, ensuring
                    your eCommerce platform is fast, secure, and scalable.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 text-center d-flex justify-content-center mt-4">
              <Link
                to="/services"
                className="mt-4 transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Our Services
                <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
              </Link>
            </div>
          </div>
        </section>
        <section className="key-features">
          <div className="container-fluid">
            <div
              className="row g-3 matop text-center"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <h2 className="heading-02 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                >
                  Key Web Development Services We Offer
                  <div className="middle-img">
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute top-100"
                    />
                  </div>
                </span>
              </h2>
            </div>
            <div className="middle-box">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12 mtop4">
                  <div
                    className="bg-shapes rotation1"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <div className="col-12">
                      <h4>Web Development</h4>
                      <p>
                        Empowering your digital presence with top-notch web
                        development services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mtop5">
                  <div
                    className="bg-shapes rotation2"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <div className="col-12">
                      <h4>Web-Apps</h4>
                      <p>
                        Delivering exceptional web app solutions to strengthen
                        your brand
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 middle-sect62 mtop6">
                  <div
                    className="bg-shapes rotation2"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <div className="col-12">
                      <h4>E-commerce</h4>
                      <p>
                        Providing powerful solutions for your e-commerce website
                        to maximize sales.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 middle-sect62 mtop7">
                  <div
                    className="bg-shapes rotation1"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <div className="col-12">
                      <h4>WordPress</h4>
                      <p>Creating WordPress websites to boost your brand.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mtop8">
                  <div
                    className="bg-shapes rotation1"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <div className="col-12">
                      <h4>CRM</h4>
                      <p>
                        By delivering a custom CRM system we help you manage
                        your customer interactions effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mtop9">
                  <div
                    className="bg-shapes rotation2"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <div className="col-12">
                      <h4>LMS</h4>
                      <p>
                        Building a unique LMS to help reform your teaching
                        techniques.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section bg-remobox2">
          <div className="container">
            <div
              className="row g-3 matop text-center"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <h2 className="heading-02 lh-sm">
                <span
                  className="position-relative d-inline-block"
                  data-aos="fade-left"
                >
                  Development Process
                  <div className="middle-img">
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute top-100"
                    />
                  </div>
                </span>
              </h2>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={requirement1} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Requirement Analysis</h5>
                  <p className="text-gray">
                    We work closely with stakeholders to understand their needs
                    and define clear project requirements.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={Design45} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Design</h5>
                  <p className="text-gray">
                    Our design team creates intuitive and user-friendly
                    interfaces, focusing on enhancing usability and overall
                    experience.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img
                      src={Implementation34}
                      alt="icon"
                      className="mx-auto"
                    />
                  </div>
                  <h5 className="heading-08 text-WH">Implementation</h5>
                  <p className="text-gray">
                    Our developers build the application using modern
                    technologies and best practices to ensure reliability and
                    performance.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={Testing45} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Testing</h5>
                  <p className="text-gray">
                    We conduct rigorous testing to identify and fix any issues,
                    ensuring the software meets quality standards.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={Deployment456} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">Deployment</h5>
                  <p className="text-gray">
                    After thorough testing, we deploy the software and provide
                    support to ensure a smooth transition.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={Maintenance4545} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH">
                    Maintenance and Support
                  </h5>
                  <p className="text-gray">
                    We offer ongoing maintenance and support to address any
                    issues and implement updates based on user feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="logoSlider-section logoSlider-aboutPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 ">
                <h2 className="heading-02 lh-sm text-center text-WH">
                  The Things
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    We Do Best
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-8 col-lg-12 mtop text-center mt-3">
                <p
                  className="p-text fw-medium text-WH "
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  Our Areas of Expertise
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center code-logo-slider">
              <Slider {...settings3}>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={htmllogo}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={wordpresslogo2}
                    alt="WORDPRESS LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1600}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={reactlogo2}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={adobe_xd_icon}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Bootstrap_logo}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={dotnet}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Nodejs_logo}
                    alt="REACT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1800}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={javascriptlogo2}
                    alt="JAVASCRIPT LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={2100}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={Laravellogo}
                    alt="LARAVEL LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={htmllogo}
                    alt="HTML LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={phplogo}
                    alt="PHP LOGO"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
