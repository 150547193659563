import { useEffect, useState } from "react";
import Recaptcha from "../Recaptcha";
import "./styles.css";
import { submitContactForm } from "src/services";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

const Index = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    services: "",
    comment: "",
  });

  const [token, setToken] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (event) => {
    if (errors) {
      setErrors({});
    }
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setToken(value);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!token) newErrors.verify = "Please Verify.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be digits only.";
    }
    if (!formData.services) newErrors.services = "Please select a service.";
    if (!formData.comment)
      newErrors.comment = "Additional comments are required.";

    return newErrors;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // await Swal.fire({
      //   title: "Error!",
      //   text: "Please fix the validation errors.",
      //   icon: "error",
      //   confirmButtonText: "OK",
      // });
    } else {
      setLoading(true);
      setErrors({});
      try {
        const response: any = await submitContactForm(formData);
        if (response) {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            phone: "",
            services: "",
            comment: "",
          });
          setToken("");
          await Swal.fire({
            title: "Success!",
            text: "Your form has been submitted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        setLoading(false);
        await Swal.fire({
          title: "Error!",
          text: "There was an issue submitting your form. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };
  const location = useLocation();  // Access current location object

  // Reset errors whenever the route changes
  useEffect(() => {
    setErrors({});
  }, [location]);
  useEffect(() => {
    const handleClick = (event) => {
      const targetLink = event.target.closest('a[href="#contact-form"]');

      if (targetLink) {
        const target = document.querySelector("#contact-form");

        if (target) {
          target.scrollIntoView({ behavior: "smooth" });

        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div>
      <section className="business-mail-section " id="contact-form">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h2 className="heading-02">Let's lift up your business!</h2>
              <p className="heading-05">
                Ready to start creating an amazing business?
              </p>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8 col-12 ">
              <form className="row" onSubmit={handleFormSubmit}>
                <div className="col-lg-6 col-md-6 col-sm-12  mb-2">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="formInput m-0"
                    value={formData.name}
                    onChange={onChange}
                  />
                  {errors.name && <span className="error p-0 m-0">{errors.name}</span>}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    className="formInput m-0"
                    value={formData.email}
                    onChange={onChange}
                  />
                  {errors.email && (
                    <span className="error p-0 m-0">{errors.email}</span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                  <input
                    type="tel"
                    maxLength={13}
                    minLength={9}
                    name="phone"
                    placeholder="Phone No"
                    className="formInput m-0"
                    value={formData.phone}
                    onChange={onChange}
                  />
                  {errors.phone && (
                    <span className="error p-0 m-0 ml-3">{errors.phone}</span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                  <div className="select1">
                    <select
                      name="services"
                      value={formData.services}
                      onChange={onChange}
                      className="m-0"
                    >
                      <option value="">Select Services</option>
                      <option value="Design">Design</option>
                      <option value="Development">Development</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                    </select>
                    {errors.services && (
                      <span className="error p-0 m-0">{errors.services}</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <textarea
                    name="comment"
                    placeholder="Comments*"
                    value={formData.comment}
                    onChange={onChange}
                    className="m-0"
                  />
                  {errors.comment && (
                    <span className="error p-0 m-0 ml-3">{errors.comment}</span>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <div className="col-xl-4 col-lg-6 col-md-8 col-12 ">
                    <button
                      type="submit"
                      disabled={loading}
                      className="fill-btn outline-btn2 w-100 rounded-pill"
                    >
                      {loading ? (
                        <Circles
                          visible={true}
                          height="30"
                          width="30"
                          ariaLabel="hourglass-loading"
                          wrapperClass="d-flex justify-content-center"
                          color="white"
                        />
                      ) : (
                        " Get a Quote"
                      )}
                    </button>
                    <div className="mt-3 d-flex justify-content-center">
                      <Recaptcha onChange={onCaptchaChange} />
                      {errors.verify && (
                        <span className="error p-0 m-0">{errors.verify}</span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
